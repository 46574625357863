import { SystemLanguage } from '@zeiss/ng-vis-common/types';
var enGB = {
  gssdesigner: {
    title: 'GSS Web designer',
    routes: {
      selectSupplement: {
        desc: 'Open Web Designer for create template',
        title: 'Create Template'
      },
      selectTemplate: {
        desc: 'Open Web Designer for edit template',
        title: 'Edit Template'
      }
    }
  }
};
var esES = {
  gssdesigner: {
    title: 'Diseñador web GSS',
    desc: 'Diseñar y crear una plantilla completamente nueva.',
    routes: {
      selectSupplement: {
        desc: 'Abrir diseñador web para crear plantilla',
        title: 'Crear plantilla'
      },
      selectTemplate: {
        desc: 'Abrir diseñador web para editar plantilla',
        title: 'Editar plantilla'
      }
    }
  }
};
var deDE = {
  gssdesigner: {
    title: 'GSS Web Designer',
    routes: {
      selectSupplement: {
        desc: 'Web Designer für das Anlegen eines neuen Templates',
        title: 'Template erstellen'
      },
      selectTemplate: {
        desc: 'Bestehendes Template mit dem Web Designer bearbeiten',
        title: 'Template bearbeiten'
      }
    }
  }
};
const GSS_DESIGNER_TRANSLATIONS = {
  [SystemLanguage['en-GB']]: enGB,
  [SystemLanguage['es-ES']]: esES,
  [SystemLanguage['de-DE']]: deDE
};

/**
 * Generated bundle index. Do not edit.
 */

export { GSS_DESIGNER_TRANSLATIONS };
